<template>
  <div class="matching">
    <div class="content">
      <!-- 匹配订单进度 start-->
      <!-- 顶部面包屑 s -->
      <bread-crumb :nav_title="nav_title" />
      <!-- 顶部面包屑 end-->
      <div class="matching_table">
        <!-- 左边订单编号 -->
        <div class="order_top" v-if="tableDatao.is_cancel == 1">
          <div class="order_number">
            订单编号：<span>{{ tableDatao.order_sn }}</span>
          </div>
          <p>已取消</p>
        </div>
        <div class="order_top" v-else>
          <div class="order_number">
            订单编号：<span>{{ tableDatao.order_sn }}</span>
          </div>
          <p v-if="tableDatao.status == 1">匹配中</p>
          <p v-else-if="tableDatao.status == 2">已匹配</p>
          <p v-else-if="tableDatao.status == 3">进行中</p>
          <p v-else-if="tableDatao.status == 4">待评价</p>
          <p v-else-if="tableDatao.status == 5">已评价</p>
          <p v-else>完成</p>
          <!-- 剩余时间 -->
          <div class="order_times">
            <span v-if="tableDatao.status == 1 && user_rule != 7"
              >剩余时间<br />{{ tableDatao.remaining_time }}</span
            >
              <el-tag
                v-if="tableDatao.status == 2"
                type="success"
                @click="
                  orderAffirm(
                    tableDatao.id,
                    tableDatao.course_number
                  )
                "
              >
                预约课程
              </el-tag>
              <el-tag v-if="tableDatao.status == 4" type="success">
                <router-link
                  :to="{ path: 'writes', query: { id: tableDatainfo[0].id } }"
                >
                  评价
                </router-link>
              </el-tag>
              <el-tag
                v-if="tableDatao.status == 6"
                type="success"
                @click="
                                $router.push(`evalution?id=${tableDatao.id}&type=1`)
                              "
              >
                查看评价
              </el-tag>
          </div>
        </div>
        <!-- 分割线 -->
        <div>
          <el-divider direction="vertical"></el-divider>
        </div>
        <!-- 步骤条 -->
        <order-state
          :orderWidth="orderWidth"
          :completeNum="6"
          :stateArr="stateArr"
        ></order-state>
      </div>
      <!-- 匹配订单进度 -->

      <!-- 课程详情 start-->
      <div class="courselist" v-if="tableDatao.status > 1">
        <el-table :data="tableDatas" border style="width: 1160;border-bottom:1px solid #ebeef5">
          <el-table-column prop="course_name" label="课程名称" width="87" />
          <el-table-column prop="class_time" label="上课时间" width="85" />
          <el-table-column prop="user_name" label="学生姓名" width="83" />
          <el-table-column prop="gwname" label="对接老师" width="71" />
          <!-- <el-table-column prop="course_duration" label="课程时长" width="81" /> -->
          <el-table-column label="课程时长" width="81">
            <template slot-scope="scope">
              <p
                class="times"
                v-if="
                  scope.row.course_duration < scope.row.confirm_course_duration
                "
              >
                {{ scope.row.course_duration}}分钟
                <span class="times">已超时</span>
              </p>
              <p v-else>{{ scope.row.course_duration }}分钟</p>
            </template>
          </el-table-column>
          <el-table-column label="确认课程时长" width="100">
            <template slot-scope="scope">
                {{ scope.row.confirm_course_duration }}分钟
            </template>
          </el-table-column>
          <el-table-column prop="teacher_name" label="海师姓名" width="71" />
          <el-table-column
            prop="order.service_name"
            label="服务类别"
            width="91"
          />
          <el-table-column
            prop="service_category_name"
            label="服务内容"
            width="91"
          />
          <el-table-column
            prop="course_price"
            label="课程单价"
            width="71"
            v-if="user_rule == 6 || user_rule == 7"
            
          >
            <template slot-scope="scope">
             {{scope.row.currency==1?'￥':'$'}}
             {{scope.row.course_price}}
            </template>
          </el-table-column>
          <el-table-column
            prop="confirm_course_price"
            label="确认单价"
            width="71"
            v-if="user_rule == 6 || user_rule == 7"
          >
            <template slot-scope="scope">
             {{scope.row.currency==1?'￥':'$'}}
             {{scope.row.confirm_course_price}}
            </template>
          </el-table-column>
          <el-table-column label="课程状态" width="81">
            <template slot-scope="scope">
              <p v-if="scope.row.is_end_course == 0">进行中</p>
              <p v-else>已结束</p>
            </template>
          </el-table-column>
          <el-table-column label="付款状态" width="81">
            <template slot-scope="scope">
              <p v-if="scope.row.is_payment == 1">已结算</p>
              <p v-else>未付款</p>
            </template>
          </el-table-column>
          <!-- 查看附件 -->
          <el-table-column prop="look_attachment" label="查看附件" width="91">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.accessory" :key="index">
                <el-link type="primary" target="_blank" :href="item.address"
                  >{{ item.filename }}.{{ item.suffix }}</el-link
                >
              </div>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column
            prop="operation"
            label="操作"
            width="104"
            v-if="
              $cookie.get('user_rule') != 2 &&
              $cookie.get('user_rule') != 3 &&
              $cookie.get('user_rule') != 7
            "
          >
            <template slot-scope="scope">
              <!-- 运营老师 只有确认课程单价 -->
              <template v-if="$cookie.get('user_rule') == 6">
                <div v-if="scope.row.is_end_course == 1">
                  <span
                    v-if="scope.row.is_course_price == 0"
                    @click="queren(scope.row)"
                    >确认单价</span
                  >
                  <span v-else class="hui">已确认单价</span>
                </div>
              </template>
              <!-- 其他 -->
              <template v-else>
                <div
                  v-if="scope.row.is_end_course == 0"
                  @click="endcoursetime(scope.row)"
                >
                  更改时间
                </div>
                <div class="operation_list">
                  <el-upload
                    class="upload-demo"
                    action="https://mentorapi.hi-daas.com/api/file-upload-fj"
                    :headers="headers"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      @click="beforeUpload(scope.row)"
                      >上传附件</el-button
                    >
                  </el-upload>
                  <div
                    v-if="scope.row.is_end_course == 0"
                    @click="endcourse(scope.row)"
                  >
                    结束课程
                  </div>
                </div>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 课程详情 end-->

      <!-- 匹配海师详情 start-->
      <div class="matching_teach">
        <!-- 课程列表 -->
        <div class="teaches">
          <el-table :data="tableDatainfo" border style="width: 1160px">
            <el-table-column prop="tableDatainfo" label="海师详情" width="320">
              <template slot-scope="scope">
                <div class="details">
                  <el-avatar :src="scope.row.hstp"> </el-avatar>
                  <div class="users">
                    <span>{{ scope.row.teacher_name }}</span>
                    <p>{{ scope.row.hs_service_name }}</p>
                  </div>
                </div>
              </template>
              <div class="appointment">
                预约时间：<span>2021-3-10 9:30 - 2021-3-12 14:30</span>
              </div>
            </el-table-column>
            <el-table-column prop="country" label="国家" width="88" />
            <el-table-column prop="school" label="院校" width="135" />
            <el-table-column prop="hd_hdsf" label="海师身份" width="135" />
            <el-table-column prop="hd_ztdf" label="海师评分" width="95" />
            <el-table-column prop="hd_zt" label="活跃度" width="121" />
            <el-table-column prop="user_name" label="学生" width="108" />
            <el-table-column prop="hd_sjh" label="手机号" width="160" />
          </el-table>
        </div>
      </div>
      <!-- 确认单价弹框 -->
      <confirmDlg v-if="confirm" :confirmItem="confirmItem"></confirmDlg>
      <!-- 匹配海师详情 end-->
    </div>
    <endCourse
      v-if="centerDialogVisible"
      :centerDialogVisible="centerDialogVisible"
      :formLabelAlign="formLabelAlign"
      @endCourseFun="endCourseFun"
    ></endCourse>
    <!-- 修改上课时间 -->
    <updatePop
      v-if="updateyuyueDialogVisible"
      :orderId="orderId"
      :updateyuyueDialogVisible="updateyuyueDialogVisible"
      :obj="obj"
      @yuyueCallbackFun="yuyueCallbackFun"
    />

    <!-- 预约上课时间 -->
    <yuyuePop
      v-if="yuyueDialogVisible"
      :orderId="orderId"
      :yuyueDialogVisible="yuyueDialogVisible"
      :yuyueList="yuyueList"
      @yuyueCallbackFun="yuyueCallbackFun"
    />
  </div>
</template>

<script>
import OrderState from "./components/OrderState";
import breadCrumb from "./components/breadcrumb";
import confirmDlg from "./components/confirmDlg/confirmDlg";

import { coursesOrderList, orderDetail, upfile2 } from "@/api/order.js";

import endCourse from "./components/pop/endCourse"; //结束课程
import updatePop from "./components/pop/updatePop"; //修改时间
import yuyuePop from "./components/pop/yuyuePop";
export default {
  data() {
    return {
      orderid: 0,
      orderId: 0,
      hours: 1,
      confirm: false, //确认单价弹窗
      confirmItem: {}, //确认单价数据
      uploadID: "", //上传附件绑定id
      user_rule: this.$cookie.get("user_rule"),
      minutes: 10,
      seconds: 0,
      headers: {
        Authorization: `Bearer ${this.$cookie.get("token")}`,
      },
      nav_title: [{ name: "订单" }, { name: "订单详情" }],
      tableDatao: {},//订单状态
      tableDatainfo: [
        {
          // ordername: "预约时间",
          // country: "美国",
          // school: "哈佛大学",
          // identity: "职场精英",
          // score: "4.85",
          // hots: "活跃",
          // student: "德鲁伊",
          // mobile: "18877903370",
        },
      ],
      tableDatas: [
        {
          names: "托福",
          course: "2020-03-10 10:20",
          stu_name: "德鲁伊",
          dock_teach: "Lily",
          course_time: "60分钟",
          haishi_name: "Kate",
          category: "头脑风暴",
          content: "写作指导",
          course_price: "¥200",
          confirm_price: "¥280",
          course_state: "已结束",
          pay_state: "已结算",
          look_attachment: "",
          operation: "",
        },
      ],
      orderWidth: 501,
      stateArrs: [
        { name: "提交预约" },
        { name: "已匹配" },
        { name: "全部结束" },
        { name: "全部结算" },
        { name: "已评价" },
        { name: "已完成" },
      ],
      stateArr: [],
      formLabelAlign: {},
      centerDialogVisible: false,
      yuyueDialogVisible: false,
      updateyuyueDialogVisible: false,
      obj: [],
      yuyueList: 1,
    };
  },
  methods: {
    //确认弹窗
    queren(item) {
      this.confirmItem = item;
      this.confirm = true;

      console.log(item);
    },
    //点击上传获取当前订单id
    beforeUpload(item) {
      this.uploadID = item.id;
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      upfile2({
        courses_order_id: this.uploadID,
        address: res.data.address,
        filename: res.data.filename,
        suffix: res.data.suffix,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          coursesOrderList({ order_id: this.orderid }).then((res) => {
            this.tableDatas = res.data;
            // console.log("coursesOrderList", res);
          });
          this.$message({
            message: res.data,
            type: "success",
          });
        }
      });
    },
    // 确认弹窗关闭回调
    confirmFlag() {
      this.loaddata();
      this.confirm = !this.confirm;
    },
    loaddata() {
      this.orderid = this.$route.query.orderid;
      // 查询评论列表
      coursesOrderList({ order_id: this.orderid }).then((res) => {
        this.tableDatas = res.data;
        // console.log("coursesOrderList", res);
      });

      orderDetail({ id: this.orderid }).then((res) => {
        let arr = [];
        arr.push(res.data.info);
        this.tableDatainfo = arr; //用户信息
        this.tableDatao = res.data.schedule; //订单状态
        this.stateArr = [];
        for (let i = 0; i < res.data.schedule.progress_code; i++) {
          this.stateArr.push(this.stateArrs[i]);
        }

        if (res.data.schedule.progress_code == 1) {
          this.stateArr.push({ name: "匹配中" });
        }
        if (res.data.schedule.is_cancel == 1) {
          this.stateArr.push({ name: "取消" });
        }
      });
    },
    endcourse(row) {
      //点击修改
      console.log(row);
      this.centerDialogVisible = true;
      this.formLabelAlign = {
        course_name: row.course_name,
        course_duration: row.course_duration,
        service_category_name: row.service_category_name,
        kcid: row.id,
      };
    },
    endcoursetime(row) {
      console.log("endcoursetime", row.class_time);
      this.orderId = row.id;
      this.obj = [];
      this.obj.push({
        course_name: row.course_name,
        class_time: row.class_time,
      });
      this.updateyuyueDialogVisible = true;
    },
    endCourseFun() {
      // 回调方法
      this.centerDialogVisible = false;
      this.loaddata();
    },
    yuyueCallbackFun(type = 0) {
      console.log(1);
      // 取消预约
      this.yuyueDialogVisible = false;
      this.updateyuyueDialogVisible = false;
      if (type == 1) {
        console.log(2);
        this.loaddata();
      }
    },
    orderAffirm(orderId, course_number = 0) {
      console.log(orderId);
      // 预约课程
      this.yuyueList = course_number;
      this.orderId = orderId;
      this.yuyueDialogVisible = true;
    },
  },
  mounted: function () {
    this.loaddata();
  },
  components: {
    OrderState,
    confirmDlg,
    breadCrumb: breadCrumb,
    endCourse: endCourse,
    updatePop,
    yuyuePop,
  },
};
</script>

<style scoped lang="scss">
.matching {
  width: 100%;
  background: #f5f5f5;
  padding-bottom: 30px;
  .content {
    width: 1200px;
    margin: 0 auto;
  }
  // 匹配订单信息
  .matching_table {
    cursor: pointer;
    width: 1200px;
    height: 215px;
    margin: 0 auto;
    background: url("./images/bjt.png") no-repeat center;
    display: flex;
    // 订单编号
    .order_top > p {
      font-size: 18px;
      font-weight: 500;
      color: #009349;
      margin: 35px 0 44px 126px;
    }
    .order_number {
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      padding: 35px 0 0 40px;
      span {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
      }
    }

    /deep/.el-tag.el-tag--success {
      background-color: #ffffff;
      border-color: #1890ff;
      color: #1890ff;
      border-radius: 4px;
      border: 1px solid rgba(24, 144, 255, 0.5);
    }
    // 剩余时间
    .order_times {
      width: 72px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      // margin: 0 0 52px 120px;
      margin-left: 118px;
    }
    // 分割线
    /deep/ .el-divider--vertical {
      display: inline-block;
      width: 1px;
      height: 1em;
      vertical-align: middle;
      position: relative;
      height: 120px;
      background: #ededed;
      margin: 0;
      margin: 48px 0 47px 58px;
    }
  }
  // 课程详情
  .courselist {
    cursor: pointer;
    width: 1200px;
    background: #ffffff;
    border-radius: 8px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20px;
    .operation_list {
      div {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
      }
      /deep/ .upload-demo {
        .el-button {
          padding: 0;
          background: none;
          border: none;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
  /deep/ .el-table .cell,
  .el-table--border td:first-child .cell,
  .el-table--border th:first-child .cell {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    text-align: center;
  }

  /deep/.el-table--border {
    border-radius: 4px;
  }

  // /deep/.el-table--scrollable-x .el-table__body-wrapper {
  //   overflow: hidden;
  // }

  // 匹配海师详情
  .matching_teach {
    cursor: pointer;
    margin: 0 auto;
    width: 1200px;
    height: 212px;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 20px;
    .teaches {
      padding: 20px;
      // 订单详情
      .details {
        display: flex;
        .users {
          margin-left: 5px;
          text-align: left;
          span {
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
          p {
            font-size: 13px;
            font-weight: 500;
            color: #333333;
          }
        }
      }
      /deep/ .el-table .cell,
      .el-table--border td:first-child .cell,
      .el-table--border th:first-child .cell {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: #666666;
      }
      .el-table th,
      .el-table tr[data-v-1b92c146] {
        background: #f5f5f5;
        border-radius: 4px 4px 0px 0px;
      }
    }
  }
  /deep/.el-table--border th,
  .el-table__fixed-right-patch {
    background: #f5f5f5;
  }
  .times {
    color: #f4523b;
    display: inline-block;
  }
  .hui {
    color: #999999;
    cursor: not-allowed;
  }
}
</style>
