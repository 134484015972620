<template>
  <div>
    <el-dialog
      title="更改预约课堂"
      :visible.sync="updateyuyueDialogVisible"
      :close-on-click-modal="false"
      width="22%"
      margin-top="30vh;"
      center
      :before-close="yuyueCallbackFun"
    >
      <!-- 顶部分割线 -->
      <el-divider></el-divider>
      <!-- 请输入课程名称 -->
      <el-row class="match_input" v-for="(item, index) in obj" :key="index">
        <el-col :span="8">
          <el-input
            class="kc"
            v-model="item.course_name"
            placeholder="课程名称1"
          >
          </el-input>
        </el-col>
        <el-col :span="16">
          <el-date-picker
            v-model="item.class_time"
            align="right"
            type="datetime"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <!-- 请选择 -->
      <!-- <div class="match_select">
        <el-select v-model="value" placeholder="请选择" style="width: 180px">
          <el-option
            v-for="(item,index) in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="optionChange(index)"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="value1"
          placeholder="请选择"
          @focus="setMinWidth"
          style="width: 180px; margin-left: 30px"
        >
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <!-- 底部分割线 -->
      <div class="fgx">
        <el-divider></el-divider>
      </div>
      <!-- 确认 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="yuyueSub">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { changeCourseTime } from "@/api/order.js";
import { object } from '@amcharts/amcharts4/core';
export default {
  data() {
    return {
      // obj: [
      //   { course_name: "", class_time: 0 },
      // ],
      course_name: "",
      // 请选择
      copyOptions:'',
      options: [  //服务流程父节点
      ],
      options1: [ //服务流程子节点
      ],
      value:'',
      value1:'',
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now()-86400000;
          },
          shortcuts: [
            {
              text: "明天",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 1);
                picker.$emit("pick", date);
              },
            },
            {
              text: "后天",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 2);
                picker.$emit("pick", date);
              },
            },
            {
              text: "一周",
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
                picker.$emit("pick", date);
              },
            },
          ],
      }
    };
  },
  props: {
    updateyuyueDialogVisible: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      default: 0,
    },
    yuyueList: {
      type: Number,
      default: 1,
    },
    obj: {
      default:[],
    }
  },
  created() {},
  methods: {
    setMinWidth(val) {
      this.minWidth = val.srcElement.clientWidth;
    },
    yuyueCallbackFun() {
      // 取消
      this.$emit("yuyueCallbackFun", false);
    },
    yuyueListFun() {
      console.log(this.obj)
      //初始化需要几节课
      // for (let a = 1; a < this.yuyueList; a++) {
      //   this.obj.push({ course_name: "", class_time: 0 });
      // }
    },
    yuyueSub() {

      console.log(111,this.obj[0].class_time)
      changeCourseTime({courses_order_id:this.orderId,new_class_time:this.obj[0].class_time}).then(res => {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
      })
      this.$emit("yuyueCallbackFun", 1);

    },


  },
  mounted() {
    this.yuyueListFun();
  },
};
</script>
<style lang="scss" scoped>
.match_input {
  display: flex;
  margin-top: 25px;
}
// 课程input
/deep/.el-dialog--center .el-dialog__body {
  // padding: 15px 25px 30px 25px;
  padding: 0;
  .kc .el-input__inner {
    width: 110px !important;
    height: 44px;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #ededed;
    margin-left: 16px;
  }
  .el-divider--horizontal {
    margin: 0;
  }
  .el-col .el-input__inner {
    width: 257px;
  }
}
// 请选择
.match_select {
  margin-top: 25px;
  margin-left: 15px;
}
/deep/.el-button--primary {
  color: #935d14;
  border: none;
  width: 350px;
  height: 50px;
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
}
.fgx {
  margin-top: 40px;
}
</style>
