<template>
  <!-- 步骤条 -->
      <div class="order_steps">
        <el-steps :space="orderWidth" :active="completeNum" finish-status="success">
          <el-step v-for="(item,index) in stateArr" :key="index" :title="item.name">

          </el-step>
        </el-steps>
      </div>
</template>
<script>
export default {
  name: "NavLeft",
  data() {
    return {};
  },
  props: {
    completeNum: {
      type: Number,
      default: 2,
    },
    orderWidth:{
      type:Number,
      default:543
    },
    stateArr:{
      default:[
        {name:'提交预约'},
        {name:'匹配中'}
      ]
    }
  },
};
</script>

<style lang="scss" scoped>
// 步骤条
.order_steps {
  width: 900px;
  padding-left: 138px;
  /deep/ .el-steps {
    padding-top: 76px;
    .el-step__line {
      height: 4px;
      background: #009349;
    }
    .el-step__title {
      font-size: 14px;
      font-weight: 500;
      color: #666666;
    }
    .el-step__icon {
      width: 30px;
      height: 30px;
    }
    .el-step__icon.is-text {
      border: 4px solid #ededed;
      background: #009349;
    }
    .el-step__head.is-success {
      border-color: #009349;
    }
    .el-step__icon-inner {
      color: #ffffff;
    }
  }
}
</style>
