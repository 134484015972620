<template>
  <div class="confirmDlg">
    <el-dialog
      title="确认课程单价"
      :visible.sync="confirmFlag"
      center
      :before-close="handleClose"
    >
      <div class="t1">
        <span>课程名称</span> <span>{{ confirmItem.course_name }}</span>
      </div>
      <div class="t1">
        <span>课程时长</span> <span>{{ confirmItem.course_duration }}分钟</span>
      </div>
      <div class="t1">
        <span>服务内容</span> <span>{{ confirmItem.course_name }}</span>
      </div>
      <div class="t1">
        <span>课程金额</span> <span>{{ confirmItem.currency==1?'￥':'$' }}{{ confirmItem.course_price }}</span>
      </div>
      <div class="t1 t2">
        <span>确认单价</span>
        <input
          type="text"
          v-model="qrdj"
          @keyup="qrdj = qrdj.replace(/\s*/g, '')"
        />元
      </div>
       <div class="t1 t2">
        <span>付款账号</span>
        <input
          type="text"
          v-model="fkzh"
          @keyup="fkzh = fkzh.replace(/\s*/g, '')"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="qr()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { confirmPrice } from "@/api/order.js";
export default {
  props: ["confirmItem"],
  data() {
    return {
      confirmFlag: true,
      qrdj: "",//确认单价
      fkzh:'',//付款账号
    };
  },
  methods: {
    //弹窗关闭回调
    handleClose() {
      this.$parent.confirmFlag();
    },
    //确认单价
    qr() {
      confirmPrice({
        courses_order_id: this.confirmItem.id,//订单ID
        confirm_course_price: this.qrdj,//确认单价
        pay_card: this.fkzh,//付款账号
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "确认单价成功",
            type: "success",
          });
          this.handleClose();
        }
      });
    },
  },
  created() {
    console.log(this.confirmItem);
  },
};
</script>

<style scoped lang="scss">
.confirmDlg {
  /deep/ .el-dialog {
    width: 450px;
    border-radius: 8px;
    overflow: hidden;
    .el-dialog__header {
      padding: 25px 0px;
      border: 1px solid #ededed;
      button {
        display: none;
      }
    }
    .el-dialog__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 90px;
      border: 1px solid #ededed;
      .t1 {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        > span {
          display: inline-block;
          &:nth-of-type(1) {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            margin-right: 20px;
          }
          &:nth-of-type(2) {
            width: 180px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .t1:last-child {
        margin-bottom: 0px;
      }
      .t2 {
        display: flex;
        align-items: center;
        input {
          width: 150px;
          height: 44px;
          background: #fafafa;
          border-radius: 4px;
          border: 1px solid #ededed;
          margin-right: 10px;
          padding-left: 10px;
          outline: none;
          box-sizing: border-box;
          &:focus {
            border: 1px solid #935d14;
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 20px 0;
      .el-button {
        width: 150px;
        height: 50px;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 400;
        &:nth-of-type(1) {
          border: 1px solid #ededed;
          background: #fff;
          color: #999999;
        }
        &:nth-of-type(2) {
          margin-left: 33px;

          background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
          color: #935d14;
          border: none;
        }
      }
    }
  }
}
</style>
