<template>
  <div>
    <el-dialog
      title="当前课程是否已结束"
      :visible.sync="centerDialogVisible"
      :close-on-click-modal="false"
      width="22%"
      center
      :before-close="clickendCourse"
    >
      <!-- 顶部分割线 -->
      <el-divider></el-divider>

      <!-- 中间内容 -->
      <div class="pop_content">
        <el-form
          :label-position="labelPosition"
          label-width="80px"
          :model="formLabelAlign"
        >
          <el-form-item label="课程名称">
            <p style="line-height: 40px">{{formLabelAlign.course_name}}</p>
          </el-form-item>
          <el-form-item label="课程时长">
            <el-input
              v-model="formLabelAlign.course_duration"
              placeholder="请输入服务时长"
            ></el-input>
            <span>分钟</span>
          </el-form-item>
          <el-form-item label="服务内容">
            <p>{{formLabelAlign.service_category_name}}</p>
          </el-form-item>
        </el-form>
      </div>

      <!-- 底部分割线 -->
      <div class="fgx">
        <el-divider></el-divider>
      </div>
      <!-- 确认 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="clickendCourse">取 消</el-button>
        <el-button type="primary" @click="affirmFun()"
          >结 束</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { endCourse } from "@/api/order.js";
export default {
  data() {
    return {
      activeName: "first",
      labelPosition: "right",

    };
  },
  props:{
    centerDialogVisible:{
      type:Boolean,
      default:false
    },
    formLabelAlign:{
      default:"",
    }

  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    clickendCourse(){
      this.$emit("endCourseFun", false);
    },
    affirmFun(){
      // 判断是否修改值
      endCourse({courses_order_id:this.formLabelAlign.kcid,confirm_course_duration:this.formLabelAlign.course_duration}).then(res =>{
        console.log(res);
        if(res.code == 200){
          this.$message({
          message: '课程结束成功',
          type: 'success'
        });
        }
      })
      this.clickendCourse()
    }

  },
};
</script>
<style lang="scss" scoped>
// 间距
/deep/.el-dialog--center .el-dialog__body {
  padding: 0;
  .el-divider--horizontal {
    margin: 0;
  }
  .el-form-item__label {
    font-size: 16px;
  }
}
// 标题间距
/deep/.el-dialog__title {
  letter-spacing: 2px;
  font-size: 20px;
}
// 课程名称
.pop_content {
  margin: 30px 0 0 0px;
  font-weight: 400;
  color: #333333;
  // 课程时长
  /deep/.el-input__inner {
    width: 160px;
    height: 44px;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #ededed;
  }
  /deep/.el-button + .el-button {
    margin-left: 40px;
  }
  /deep/.el-input {
    width: 160px;
  }
  /deep/.el-form {
    margin-left: 70px;
    margin-top: 35px;
  }
  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }
  p {
    width: 180px;
    line-height: 40px;
    font-size: 16px;
  }
}

// 取消
/deep/.el-button {
  width: 150px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ededed;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
// 确认
/deep/.el-button--primary {
  color: #935d14;
  border: none;
  width: 150px;
  height: 50px;
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
}
.fgx {
  margin-top: 40px;
}
</style>
